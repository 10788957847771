unit App.Types;

interface

uses
  JS,
  System.SysUtils,
  WEBLib.Dialogs,
  WEBLib.Forms,
  XData.Web.Connection,
  XData.Web.Client;

type

  TViewOption = (voNone, voReadOnly, voEdit, voCreateAndEdit);
  TValueChangeState = (vcsNoChange, vcsValid, vcsInvalid);
  TRefStatus = (RefEmpty, RefNotChecked, RefChecking, RefUnique, RefInUse);

  TProc = reference to procedure;

  TUserChangedProc = procedure(const LastUserId, NewUserId: Integer) of object;
  TBeforeLogoutEvent = procedure(const IsUserInitiated: boolean) of object;

  TSuccessProc = reference to procedure;
  TLogoutProc = reference to procedure(AMessage: string = ''; const LoggingOut: boolean = False);
  TUnauthorizedAccessProc = reference to procedure(AMessage: string);

  TListProc = reference to procedure;
  TEditProc = reference to procedure(AId: JSValue);

  TShowMessageProc = procedure(const AMessage: string; DlgType: TMsgDlgType) of object;
  TErrorMessageProc = procedure(AMessage: string) of object;
  TXDataErrorProc = procedure(Value: TApplicationError; var AHandled: boolean) of object;

  EXDataClientException = class(Exception)
  private
    FRequestId: string;
    FRequestUrl: string;
    FErrorCode: string;
    FStatusCode: Integer;
  public
    constructor Create(AnError: TXDataClientError);
    property StatusCode: Integer read FStatusCode;
    property RequestUrl: string read FRequestUrl;
    property RequestId: string read FRequestId;
    property ErrorCode: string read FErrorCode;
  end;

  EApplicationException = class(Exception)
  private
    FColNumber: Integer;
    FStack: string;
    FFile: string;
    FLineNumber: Integer;
    FErrorCount: Integer;
  public
    constructor Create(AnError: TApplicationError; const AErrorCount: Integer = 1);
    property AFile: string read FFile;
    property LineNumber: Integer read FLineNumber;
    property ColNumber: Integer read FColNumber;
    property Stack: string read FStack;
    property ErrorCount: Integer read FErrorCount;
  end;

  EXDataConnectionError = class(Exception)
  private
    FRequestURL: String;
    FStatusReason: String;
    FStatusCode: Integer;
  public
    constructor Create(AnError: TXDataWebConnectionError);
    property RequestURL: string Read FRequestURL;
    property StatusCode: Integer read FStatusCode;
    property StatusReason: String read FStatusReason;
  end;

implementation

{ EXDataException }

constructor EXDataClientException.Create(AnError: TXDataClientError);
begin
  FStatusCode := AnError.StatusCode;
  FRequestUrl := AnError.RequestUrl;
  FRequestId := AnError.RequestId;
  FErrorCode := AnError.ErrorCode;
  Message := AnError.ErrorMessage;
  // property Response: IHttpResponse read FResponse;
end;

{ EApplicationException }

constructor EApplicationException.Create(AnError: TApplicationError; const AErrorCount: Integer);
begin
  // property Error: TJSObject;
  FErrorCount := AErrorCount;
  FColNumber := AnError.AColNumber;
  FStack := AnError.AStack;
  Message := AnError.AMessage;
  FFile := AnError.AFile;
  FLineNumber := AnError.ALineNumber;
end;

{ EXDataConnectionError }

constructor EXDataConnectionError.Create(AnError: TXDataWebConnectionError);
begin
  Message := AnError.ErrorMessage;
  FRequestURL := AnError.RequestURL;
  FStatusCode := AnError.Response.StatusCode;
  FStatusReason := AnError.Response.StatusReason;
end;

end.
