/// <summary>
/// This unit can be shared between the server and the webcore app
/// </summary>
unit smx.Reports.Types;

interface

type

  TReportIdType = (ridReport, ridMyDashboard);

  TReportProc = (rpStandard, rpCrosstab, rpScript);

  TParamValueType = (Single, Range, List);

  TParamOperator = (Equals, GreatThan, GreatThanOrEqualTo, LessThan, LessThanOrEqualTo, NotEqualTo, StartsWith);

  TParamDefault = (pdNoDefault, pdStartOfThisYear, pdStartOfThisMonth, pdStartOfThisWeek, pdToday, pdNow, pdYesterday,
    pdEndOfYesterday, pdStartOfLastYear, pdEndofLastYear, pdStartOfLastMonth, pdEndOfLastMonth, pdStartOfLastWeek,
    pdEndOfLastWeek, pdStartOfThisQuarter, pdStartOfLastQuarter, pdEndOfLastQuarter, pdStartOfTaxYear,
    pdStartOfLastTaxYear, pdEndOfLastTaxYear, pdPreviousWorkingDay, pdPreviousWorkingDayEnd, pdIncDay, pdIncWeek, pdIncMonth);

  /// <summary>
  /// This maps directly to TDashboardDisplayType in smx.Reports.Entities
  /// </summary>
  TDashboardDisplay = (ddNone, ddText, ddGraph, ddTable, ddHTMLFile);
  /// <summary>
  /// This maps directly to TRefreshFrequency in smx.Reports.Entities
  /// </summary>
  TRefreshFreq = (rfManual, rfAlways, rfHourly, rfDaily, rfWeekly, rfMonthly, rfAnnually);

  TDashboardItemStatus = (disOK, disExpired, disNoRights, disError);

  THorizontalAlign = (halLeft, halCenter, halRight);

  TColProcess = (cpNone, cpIgnore, cpWeekNumber);

const
  DASHBOARD_CLASS: array [TDashboardDisplay] of string = ('None', 'Text', 'Graph', 'Table', 'HTML');
  HALIGN_CLASS: array [THorizontalAlign] of string = ('left-align', 'center-align', 'right-align');
  TABLE_CLASS: array[TReportProc] of string =('tableStandard', 'tableCrossTab', 'tableScript');

implementation

end.
