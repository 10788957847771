program GFSMCPWebApp;

uses
  Vcl.Forms,
  WEBLib.Forms,
  MainForm in 'MainForm.pas'{*.html},
  MainDataModule in 'MainDataModule.pas',
  AppConfig in 'AppConfig.pas',
  DashboardForm in 'DashboardForm.pas'{*.html},
  Auth.Service in 'Core\Auth.Service.pas',
  App.Types in 'Core\App.Types.pas',
  LoginForm in 'LoginForm.pas'{*.html},
  BaseList in 'BaseList.pas'{*.html},
  Dataset.Plugins in 'Core\Dataset.Plugins.pas',
  Grid.Plugins in 'Core\Grid.Plugins.pas',
  Grid.Settings in 'Core\Grid.Settings.pas',
  Paginator.Plugins in 'Core\Paginator.Plugins.pas',
  BaseForm in 'BaseForm.pas'{*.html},
  UserForm in 'UserForm.pas'{*.html},
  UserList in 'UserList.pas'{*.html},
  JobDetailForm in 'JobDetailForm.pas'{*.html},
  SelfUpdateForm in 'SelfUpdateForm.pas'{*.html},
  BaseCoreForm in 'BaseCoreForm.pas'{*.html},
  ForgottenPasswordForm in 'ForgottenPasswordForm.pas'{*.html},
  JobBaseForm in 'JobBaseForm.pas'{*.html},
  JobErrorForm in 'JobErrorForm.pas'{*.html},
  SMX.Web.Document.Utils in 'C:\SMXi Libraries\Web-Core-Utils\SMX.Web.Document.Utils.pas',
  SMX.Web.FileSupport in 'C:\SMXi Libraries\Web-Core-Utils\SMX.Web.FileSupport.pas',
  SMX.Web.Utils in 'C:\SMXi Libraries\Web-Core-Utils\SMX.Web.Utils.pas',
  Dashboard.ReturnTypes in '..\..\XData-Core\Reporting\Dashboard.ReturnTypes.pas',
  smx.Reports.Types in '..\..\XData-Core\Reporting\smx.Reports.Types.pas',
  InputQueryModule in 'InputQueryModule.pas'{*.html},
  SMX.Auth.Shared in '..\..\XData-Core\Auth-NonSphinx\SMX.Auth.Shared.pas',
  SMX.CRM.Utils in 'C:\SMXi Libraries\Web-Core-Utils\SMX.CRM.Utils.pas',
  SMX.Web.Layout.Utils in 'C:\SMXi Libraries\Web-Core-Utils\SMX.Web.Layout.Utils.pas',
  SupportForm in 'SupportForm.pas'{*.html},
  ServerList in 'ServerList.pas'{*.html},
  ServerEdit in 'ServerEdit.pas'{*.html},
  SitesList in 'SitesList.pas'{*.html},
  SiteForm in 'SiteForm.pas'{*.html};

{$R *.res}

procedure DisplayLoginPage(AMessage: string = ''; const LoggingOut: Boolean = False); forward;

procedure DisplayMainView;

  procedure ConnectProc;
  begin
    if Assigned(LoginPage) then
    begin
      LoginPage.Free;
      LoginPage := Nil;
    end;

    TMainPage.Display('publishhere', @DisplayLoginPage);
  end;

begin
  if not MainData.DataConnection.Connected then
    MainData.DataConnection.Open(@ConnectProc)
  else
    ConnectProc;
end;

procedure DisplayLoginPage(AMessage: string; const LoggingOut: Boolean);
begin
  if Assigned(LoginPage) then
     Exit;
  if LoggingOut then
  begin
    if Assigned(MainPage) then
    begin
      MainPage.Free;
      MainPage := Nil;
    end;
  end;
  AuthService.Logout(LoggingOut);
  MainData.DataConnection.Connected := False;
  TLoginPage.Display('publishhere', @DisplayMainView, AMessage);
end;

procedure UnauthorizedAccessProc(AMessage: string);
begin
  MainPage.ReLogin(AMessage);
//  DisplayLoginPage(AMessage);
end;

procedure StartApplication;
begin
  if (not AuthService.Authenticated) or AuthService.TokenExpired then
    DisplayLoginPage
  else
    DisplayMainView;
end;

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TMainData, MainData);
  Application.Run;
  MainData.InitApp(@StartApplication, @UnauthorizedAccessProc)
end.
