unit smx.Reports.Support;

interface

uses
  smx.Reports.Types;

type

  TReportSupport = class
  public
    class function QuarterOf(const Value: TDateTime): Word;
    class function StartOfAQuarter(const AQuarter, AYear: Word): TDateTime;
    class function StartOfTheQuarter(const Value: TDateTime): TDateTime;
    class function StartOfLastQuarter(const Value: TDateTime): TDateTime;
    class function EndOfLastQuarter(const Value: TDateTime): TDateTime;
    class function EndOfAQuarter(const AQuarter, AYear: Word): TDateTime;
    class function StartOfTaxYear(const Value: TDate): TDateTime;
    class function EndOfTaxYear(const Value: TDate): TDateTime;
    class function GetPreviousWorkingDay(const Value: TDate): TDateTime;
    class function GetStandardDate(const ADefault: TParamDefault; const
        AQualifier: Integer): TDate;
    class function GetStandardDateTime(const ADefault: TParamDefault; const
        AQualifier: Integer): TDateTime;
     class function GetQualifiedDate(const ADefault: TParamDefault; const
        AQualifier: Integer): TDateTime;
    class function NeedsRefresh(const RefreshFreq: TRefreshFreq; const LastRefresh: TDateTime): boolean;
  end;

implementation

uses
  System.DateUtils,
  System.SysUtils;

const
  C_START_OPTIONS = [pdStartOfThisYear, pdStartOfThisMonth, pdStartOfThisWeek, pdToday, pdYesterday, pdStartOfLastYear,
    pdStartOfLastMonth, pdStartOfLastWeek, pdStartOfThisQuarter, pdStartOfLastQuarter, pdStartOfTaxYear,
    pdStartOfLastTaxYear, pdPreviousWorkingDay];
  C_END_OPTIONS = [pdYesterday, pdEndofLastYear, pdEndOfLastMonth, pdEndOfLastWeek, pdEndOfLastQuarter,
    pdEndOfLastTaxYear, pdPreviousWorkingDayEnd];
  C_QUALIFIED_OPTION = [pdIncDay, pdIncWeek, pdIncMonth];

  { TReportSupport }

class function TReportSupport.EndOfAQuarter(const AQuarter, AYear: Word): TDateTime;
begin
  case AQuarter of
    1:
      Result := EndOfAMonth(AYear, 3);
    2:
      Result := EndOfAMonth(AYear, 6);
    3:
      Result := EndOfAMonth(AYear, 9);
    4:
      Result := EndOfAMonth(AYear, 12);
  else
    Result := 0;
  end;
end;

class function TReportSupport.EndOfLastQuarter(const Value: TDateTime): TDateTime;
var
  lQuarter, lYear: Word;
begin
  lQuarter := QuarterOf(Value);
  lYear := YearOf(Value);
  if lQuarter = 1 then
  begin
    lQuarter := 4;
    lYear := lYear - 1;
  end
  else
    lQuarter := lQuarter - 1;

  Result := EndOfAQuarter(lQuarter, lYear);

end;

class function TReportSupport.EndOfTaxYear(const Value: TDate): TDateTime;
var
  lYear, lMonth, lDay: Word;
begin
  DecodeDate(Value, lYear, lMonth, lDay);

  if (lMonth = 4) then
  begin
    if lDay > 5 then
      lYear := lYear + 1
  end
  else if lMonth > 4 then
  begin
    lYear := lYear + 1;
  end;

  Result := EncodeDate(lYear, 4, 5);

end;

class function TReportSupport.GetPreviousWorkingDay(const Value: TDate): TDateTime;
begin
   repeat
    result := IncDay(Value, -1);
  until ((DayOf(Value) < 6)); //(not IsWeekend(result)) and (not IsBankHoliday(result)));
end;

class function TReportSupport.GetQualifiedDate(const ADefault: TParamDefault; const AQualifier: Integer): TDateTime;
begin
  case ADefault of
    pdIncDay: Result := IncDay(Today, AQualifier);
    pdIncWeek: Result := StartOfTheWeek(IncWeek(Today, AQualifier));
    pdIncMonth: Result := StartOfTheMOnth(IncMonth(Today, AQualifier));
  end;
end;

class function TReportSupport.GetStandardDate(const ADefault: TParamDefault;
    const AQualifier: Integer): TDate;
begin

  case ADefault of
    // NoParamDefault: ;
    pdStartOfThisYear:
      Result := StartOfTheYear(Today);
    pdStartOfThisMonth:
      Result := StartOfTheMonth(Today);
    pdStartOfThisWeek:
      Result := StartOfTheWeek(Today);
    pdToday:
      Result := Today;
    pdNow:
      Result := Today;
    pdYesterday:
      Result := Yesterday;
    pdStartOfLastYear:
      Result := StartOfTheYear(IncYear(Today, -1));
    pdEndofLastYear:
      Result := EndOfTheYear(IncYear(Today, -1));
    pdStartOfLastMonth:
      Result := StartOfTheMonth(IncMonth(Today, -1));
    pdEndOfLastMonth:
      Result := EndOfTheMonth(IncMonth(Today, -1));
    pdStartOfLastWeek:
      Result := StartOfTheWeek(IncWeek(Today, -1));
    pdEndOfLastWeek:
      Result := EndOfTheWeek(IncWeek(Today, -1));
    pdStartOfThisQuarter:
      Result := StartOfTheQuarter(Today);
    pdStartOfLastQuarter:
      Result := StartOfLastQuarter(Today);
    pdEndOfLastQuarter:
      Result := EndOfLastQuarter(Today);
    pdStartOfTaxYear:
      Result := StartOfTaxYear(Today);
    pdStartOfLastTaxYear:
      Result := StartOfTaxYear(IncYear(Today, -1));
    pdEndOfLastTaxYear:
      Result := EndOfTaxYear(IncYear(Today, -1));
    pdPreviousWorkingDay: Result := GetPreviousWorkingDay(Today);
    pdPreviousWorkingDayEnd: Result := GetPreviousWorkingDay(Today);
    pdIncDay,
    pdIncWeek,
    pdIncMonth: Result := GetQualifiedDate(ADefault, AQualifier);
  else
    Result := 0;
  end;

end;

class function TReportSupport.GetStandardDateTime(const ADefault: TParamDefault; const AQualifier: Integer): TDateTime;
begin

  if ADefault = pdNow then
    Exit(Now);

  Result := GetStandardDate(ADefault, AQualifier);

  if (ADefault in C_END_OPTIONS) then
    Result := EndOfTheDay(Result)
  else if (ADefault in C_START_OPTIONS) then
    Result := StartOfTheDay(Result);

end;

class function TReportSupport.NeedsRefresh(const RefreshFreq: TRefreshFreq; const LastRefresh: TDateTime): boolean;
begin
  case RefreshFreq of
    rfManual:
      Result := False;
    rfAlways:
      Result := True;
    rfHourly:
      Result := (HoursBetween(Now, LastRefresh) >= 1);
    rfDaily:
      Result := (DaysBetween(Now, LastRefresh) >= 1);
    rfWeekly:
      Result := (WeeksBetween(Now, LastRefresh) >= 1);
    rfMonthly:
      Result := (MonthsBetween(Now, LastRefresh) >= 1);
    rfAnnually:
      Result := (YearsBetween(Now, LastRefresh) >= 1);
  end;
end;

class function TReportSupport.QuarterOf(const Value: TDateTime): Word;
begin
  Result := ((MonthOf(Value) - 1) div 3) + 1;
end;

class function TReportSupport.StartOfAQuarter(const AQuarter, AYear: Word): TDateTime;
begin
  case AQuarter of
    1:
      Result := StartOfAMonth(AYear, 1);
    2:
      Result := StartOfAMonth(AYear, 4);
    3:
      Result := StartOfAMonth(AYear, 7);
    4:
      Result := StartOfAMonth(AYear, 10);
  else
    Result := 0;
  end;
end;

class function TReportSupport.StartOfLastQuarter(const Value: TDateTime): TDateTime;
var
  lQuarter, lYear: Word;
begin
  lQuarter := QuarterOf(Value);
  lYear := YearOf(Value);
  if lQuarter = 1 then
  begin
    lQuarter := 4;
    lYear := lYear - 1;
  end
  else
    lQuarter := lQuarter - 1;

  Result := StartOfAQuarter(lQuarter, lYear);
end;

class function TReportSupport.StartOfTaxYear(const Value: TDate): TDateTime;
var
  lYear, lMonth, lDay: Word;
begin
  DecodeDate(Value, lYear, lMonth, lDay);

  if (lMonth = 4) then
  begin
    if lDay < 6 then
      lYear := lYear - 1
  end
  else if lMonth < 4 then
  begin
    lYear := lYear - 1;
  end;

  Result := EncodeDate(lYear, 4, 6);
end;

class function TReportSupport.StartOfTheQuarter(const Value: TDateTime): TDateTime;
var
  lQuarter, lYear: Word;
begin
  lQuarter := QuarterOf(Value);
  lYear := YearOf(Value);
  Result := StartOfAQuarter(lQuarter, lYear);
end;

end.
