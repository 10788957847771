unit MainDataModule;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Modules,
  WEBLib.Forms,
  XData.Web.Connection,
  AppConfig,
  WEBLib.REST,
  XData.Web.Client,
  App.Types,
  Dashboard.ReturnTypes,
  smx.Dashboard.store,
  WEBLib.Sentry;

type

  TOnDashboardItemReady = procedure(AItem: TDashboardBase) of object;
  TOnClearDashboard = procedure of object;

  TMainData = class(TDataModule)
    DataConnection: TXDataWebConnection;
    WebClient: TXDataWebClient;
    AuthConnection: TXDataWebConnection;
    AuthClient: TXDataWebClient;
    WebSentry1: TSentry;
    procedure WebDataModuleDestroy(Sender: TObject);
    procedure WebDataModuleCreate(Sender: TObject);
    procedure DataConnectionError(Error: TXDataWebConnectionError);
    procedure DataConnectionRequest(Args: TXDataWebConnectionRequest);
    procedure DataConnectionResponse(Args: TXDataWebConnectionResponse);
    procedure WebClientError(Error: TXDataClientError);
  private
    { Private declarations }
    // FLocalErrorHandling: TApplicationErrorType;
    FShowMessageProc: TShowMessageProc;
    FErrorProc: TErrorMessageProc;
    FUnauthorizedAccessProc: TUnauthorizedAccessProc;
    FOnAuthUpdatedProc: TNotifyEvent;
    FLastError: TApplicationError;
    FLastErrorTime: TDateTime;
    FErrorCount: Integer;
    FDashboardStore: TDashboardStore;
    FShops: TStrings;
    FCompanyYearEnd: string;

    FOnDashboardItem: TOnDashboardItemReady;

    FOnClearDashboard: TOnClearDashboard;
    FOnUserChanged: TNotifyEvent;

    [async]
    procedure GetDashBoardList; async;
    [async]
    procedure RefreshDashBoardItem(DashBoardId: Integer); async;
    procedure SetOnDashboardItem(const Value: TOnDashboardItemReady);

    procedure UserChanged(const LastUserId, NewUserId: Integer);
    procedure BeforeLogout(const IsUserInitiated: Boolean);

    procedure SentryErrorProc(E: Exception; const AComment: string; var AHandled: Boolean;
      const CallUIHandler: Boolean = True);

  public
    procedure OnApplicationError(Sender: TObject; AError: TApplicationError; var Handled: Boolean);
    procedure InitApp(SuccessProc: TSuccessProc; UnauthorizedAccessProc: TUnauthorizedAccessProc);
    procedure PrepareDashboard;
    procedure ClearDashboard;
    procedure AuthCreated;

    [async]
    function ShopLookups: TStrings; async;
    [async]
    function CompanyYearEnd: string; async;
    property ShowMessageProc: TShowMessageProc write FShowMessageProc;
    property ErrorProc: TErrorMessageProc write FErrorProc;

    property OnAuthUpdatedProc: TNotifyEvent read FOnAuthUpdatedProc write FOnAuthUpdatedProc;
    property OnDashboardItem: TOnDashboardItemReady read FOnDashboardItem write SetOnDashboardItem;
    property OnClearDashboard: TOnClearDashboard read FOnClearDashboard write FOnClearDashboard;
    property OnUserChanged: TNotifyEvent read FOnUserChanged write FOnUserChanged;
  protected procedure LoadDFMValues; override; end;

var
  MainData: TMainData;

implementation

uses
  System.DateUtils,
  Auth.Service,
  WEBLib.Dialogs,
  smx.Reports.Types,
  smx.Reports.Support,
  DashboardManager;

{%CLASSGROUP 'Vcl.Controls.TControl'}
{$R *.dfm}

const
  WEB_SENTRY_DSN = 'https://defe39f6ab2d49588425185375cb64e0@o4504537489473536.ingest.sentry.io/4504713783869440';

procedure TMainData.WebDataModuleDestroy(Sender: TObject);
begin
  FDashboardStore.Free;
end;

procedure TMainData.WebDataModuleCreate(Sender: TObject);
begin
  WebSentry1.DSN := WEB_SENTRY_DSN;
{$IFDEF RELEASE}
  WebSentry1.Release := 'GFSMCP-Live@' + Application.Version;
{$ELSE}
  WebSentry1.Release := 'GFSMCP-Dev@' + Application.Version;
{$ENDIF}
  WebSentry1.Init;

  FormatSettings.DateSeparator := '/';
  FormatSettings.ShortDateFormat := 'dd/mm/yyyy';
  Application.ErrorType := aeSilent; // aeSilent, aeDialog, aeAlert, aeFooter
  Application.OnError := self.OnApplicationError;

  FDashboardStore := TDashboardStore.Create;

end;

procedure TMainData.AuthCreated;
begin
  AuthService.OnUserChanged := UserChanged;
  AuthService.BeforeLogout := BeforeLogout;
end;

procedure TMainData.BeforeLogout(const IsUserInitiated: Boolean);
begin
  if IsUserInitiated then
    ClearDashboard;
end;

procedure TMainData.ClearDashboard;
begin
  FDashboardStore.Clear;
  if Assigned(FOnClearDashboard) then
    FOnClearDashboard();
end;

procedure TMainData.DataConnectionError(Error: TXDataWebConnectionError);
var
  lHandled: Boolean;
begin
  lHandled := False;
  SentryErrorProc(EXDataConnectionError.Create(Error), 'XData Connection Error', lHandled);
end;

procedure TMainData.DataConnectionRequest(Args: TXDataWebConnectionRequest);
begin
  if AuthService.Authenticated then
    Args.Request.Headers.SetValue('Authorization', 'Bearer ' + AuthService.GetToken);
end;

procedure TMainData.DataConnectionResponse(Args: TXDataWebConnectionResponse);
var
  LToken: string;
begin
  if Args.Response.StatusCode = 401 then
    FUnauthorizedAccessProc(Format('%d: %s', [Args.Response.StatusCode, Args.Response.ContentAsText]))
  else if Args.Response.Headers.GetIfExists('jwtupd', LToken) then
  begin
    // Update JWT
    AuthService.SetToken(LToken);
    if Assigned(FOnAuthUpdatedProc) then
      FOnAuthUpdatedProc(self);
  end;

end;

function TMainData.CompanyYearEnd: string;
const
  CSvc = 'IGAUtilsService.GetLookup';
var
  lRetval: TXDataClientResponse;
  lObj: JS.TJSObject;
begin
  if FCompanyYearEnd = '' then
  begin
    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(CSvc, ['COMPANY-YEAR-END']));
    lObj := JS.toObject(lRetval.ResultAsObject['value']);
    if lObj <> nil then
    begin
      FCompanyYearEnd := JS.ToString(lObj.Properties['Value']);
    end;
  end;
  Result := FCompanyYearEnd;
end;

procedure TMainData.InitApp(SuccessProc: TSuccessProc; UnauthorizedAccessProc: TUnauthorizedAccessProc);

  procedure ConfigLoaded(Config: TAppConfig);
  begin

    if Config.BaseUrl <> '' then
    begin
      DataConnection.URL := Config.BaseUrl;
    end;

    if Config.AuthURL <> '' then
      AuthConnection.URL := Config.AuthURL
    else
      AuthConnection.URL := Config.BaseUrl;

    AuthConnection.Open(SuccessProc);

  end;

begin
  FUnauthorizedAccessProc := UnauthorizedAccessProc;
  LoadConfig(@ConfigLoaded);
end;

procedure TMainData.OnApplicationError(Sender: TObject; AError: TApplicationError; var Handled: Boolean);
begin

  FLastErrorTime := Now;

  if (AError.ALineNumber = FLastError.ALineNumber) and (SecondsBetween(Now, FLastErrorTime) < 60) then
  begin
    Inc(FErrorCount);
  end
  else
  begin
    FLastError.ALineNumber := AError.ALineNumber;
    FErrorCount := 1;
  end;

  SentryErrorProc(EApplicationException.Create(AError, FErrorCount), '', Handled);

end;

procedure TMainData.PrepareDashboard;
begin
  GetDashBoardList;
end;

procedure TMainData.GetDashBoardList;
const
  IDashBoardSvc_MyDashBoard = 'IDashboardService.MyDashboard';
var
  lRetval: TXDataClientResponse;
  lList: JS.TJSArray;
  I: Integer;
begin
  FDashboardStore.Clear;
  lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(IDashBoardSvc_MyDashBoard, []));
  lList := JS.ToArray(lRetval.ResultAsObject['value']);
  for I := 0 to lList.Length - 1 do
  begin
    RefreshDashBoardItem(JS.toInteger(lList[I]))
  end;
end;

function TMainData.ShopLookups: TStrings;
const
  CSvc = 'IGAUtilsService.GetShopsList';
var
  lRetval: TXDataClientResponse;
  lList: JS.TJSArray;
  lObj: JS.TJSObject;
  I: Integer;
begin
  if FShops = nil then
  begin
    FShops := TStringList.Create;
    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(CSvc, []));
    lList := JS.ToArray(lRetval.ResultAsObject['value']);
    for I := 0 to lList.Length - 1 do
    begin
      lObj := JS.toObject(lList[I]);
      FShops.AddPair(JS.ToString(lObj.Properties['Name']) + ' (' + JS.ToString(lObj.Properties['Status']) + ')',
        JS.ToString(lObj.Properties['Ref']));
    end;

  end;
  Result := FShops;
end;

procedure TMainData.RefreshDashBoardItem(DashBoardId: Integer);
const
  IDashBoardSvc_DashboardItem = 'IDashboardService.DashBoardItem';
  // (const Id: Integer; const IdType: TReportIdType): TDashboardBase;
var
  lRetval: TXDataClientResponse;
  AItem: TDashboardBase;
  AObj: JS.TJSObject;
  lHandled: Boolean;
begin

  AItem := FDashboardStore.ItemById[DashBoardId];
  if (AItem = nil) or (TReportSupport.NeedsRefresh(AItem.Refresh, AItem.LastRefresh)) then
  begin
    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(IDashBoardSvc_DashboardItem,
      [DashBoardId, ridMyDashboard]));

    AObj := lRetval.ResultAsObject;

    if AItem <> nil then
    begin
      FDashboardStore.UpdateFromJSON(AItem, AObj);
    end
    else
    begin
      AItem := FDashboardStore.AddFromJSON(AObj);
    end;

    if AItem.Status = TDashboardItemStatus.disError then
    begin
      lHandled := True;
      SentryErrorProc(EDashboardException.Create(AItem), '', lHandled, AuthService.IsSuperUser);
    end;

  end;

  if Assigned(FOnDashboardItem) then
  begin
    try
      FOnDashboardItem(AItem);
    except
    end;
  end;

end;

procedure TMainData.SentryErrorProc(E: Exception; const AComment: string; var AHandled: Boolean;
  const CallUIHandler: Boolean);
var
  sendException: TJSObject;
begin

  if E.Message.Contains('FServerRecordCount') then
  begin
    // Swallow the excpetion - caused by a page being released before being fully loaded
{$IFDEF DEBUG}
    if CallUIHandler and Assigned(FErrorProc) then
      FErrorProc('Error: ' + E.Message + '. Not logged and Not notified in Release Version');
{$ENDIF}
    Exit;
  end;

  sendException := TJSObject(E);

{$IFDEF RELEASE}
  WebSentry1.CaptureException(sendException, AComment);
  if CallUIHandler and Assigned(FErrorProc) then
    FErrorProc('There has been an error and the developers have been notified');
  Exit;
{$ENDIF}
{$IFDEF DEBUG}
  WebSentry1.CaptureException(sendException, AComment);
  if Assigned(FErrorProc) then
    FErrorProc('Error: ' + E.Message + '. NOT Logged at WebSentry');
{$ENDIF}
end;

procedure TMainData.SetOnDashboardItem(const Value: TOnDashboardItemReady);
var
  I: Integer;
begin
  FOnDashboardItem := Value;
  if Assigned(FOnDashboardItem) then
  begin
    for I := 0 to FDashboardStore.Count - 1 do
    begin
      try
        FOnDashboardItem(FDashboardStore.Items[I]);
      except
        on E: Exception do
        begin
          break;
        end;
      end;
    end;
  end;

end;

procedure TMainData.UserChanged(const LastUserId, NewUserId: Integer);
begin
  WebSentry1.SetUser(AuthService.UserId.ToString + ':' + AuthService.UserFullName);
  ClearDashboard;
  if Assigned(FOnUserChanged) then
    FOnUserChanged(self);
end;

procedure TMainData.WebClientError(Error: TXDataClientError);
var
  lHandled: Boolean;
begin
  lHandled := False;
  SentryErrorProc(EXDataClientException.Create(Error), 'XData Client Error', lHandled);
end;

procedure TMainData.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DataConnection := TXDataWebConnection.Create(Self);
  WebClient := TXDataWebClient.Create(Self);
  AuthConnection := TXDataWebConnection.Create(Self);
  AuthClient := TXDataWebClient.Create(Self);
  WebSentry1 := TSentry.Create(Self);

  DataConnection.BeforeLoadDFMValues;
  WebClient.BeforeLoadDFMValues;
  AuthConnection.BeforeLoadDFMValues;
  AuthClient.BeforeLoadDFMValues;
  WebSentry1.BeforeLoadDFMValues;
  try
    Name := 'MainData';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    SetEvent(Self, 'OnDestroy', 'WebDataModuleDestroy');
    Height := 150;
    Width := 323;
    DataConnection.SetParentComponent(Self);
    DataConnection.Name := 'DataConnection';
    DataConnection.URL := 'http://localhost:2018/GFSMCP';
    SetEvent(DataConnection, Self, 'OnError', 'DataConnectionError');
    SetEvent(DataConnection, Self, 'OnRequest', 'DataConnectionRequest');
    SetEvent(DataConnection, Self, 'OnResponse', 'DataConnectionResponse');
    DataConnection.Left := 40;
    DataConnection.Top := 24;
    WebClient.SetParentComponent(Self);
    WebClient.Name := 'WebClient';
    WebClient.Connection := DataConnection;
    SetEvent(WebClient, Self, 'OnError', 'WebClientError');
    WebClient.Left := 40;
    WebClient.Top := 88;
    AuthConnection.SetParentComponent(Self);
    AuthConnection.Name := 'AuthConnection';
    AuthConnection.Left := 136;
    AuthConnection.Top := 24;
    AuthClient.SetParentComponent(Self);
    AuthClient.Name := 'AuthClient';
    AuthClient.Connection := AuthConnection;
    AuthClient.Left := 136;
    AuthClient.Top := 88;
    WebSentry1.SetParentComponent(Self);
    WebSentry1.Name := 'WebSentry1';
    WebSentry1.Enabled := True;
    WebSentry1.Release := '1.0';
    WebSentry1.Left := 248;
    WebSentry1.Top := 88;
  finally
    DataConnection.AfterLoadDFMValues;
    WebClient.AfterLoadDFMValues;
    AuthConnection.AfterLoadDFMValues;
    AuthClient.AfterLoadDFMValues;
    WebSentry1.AfterLoadDFMValues;
  end;
end;

end.
