unit SiteForm;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseForm, WEBLib.ExtCtrls, WEBLib.Actions, Data.DB, WEBLib.DB, WEBLib.WebCtrls,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TSiteEditForm = class(TBaseEditForm)
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  SiteEditForm: TSiteEditForm;

implementation

{$R *.dfm}

procedure TSiteEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
  finally
  end;
end;

end.