unit UserList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Lists,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  WEBLib.WebCtrls,
  WEBLib.Toast,
  smx.Web.Utils,
  App.Types;

type
  TUserListForm = class(TBaseListForm)
    MainDatasetId: TIntegerField;
    MainDatasetTitle: TStringField;
    MainDatasetFirstName: TStringField;
    MainDatasetLastName: TStringField;
    MainDatasetUserName: TStringField;
    MainDatasetEmail: TStringField;
    MainDatasetMobile: TStringField;
    MainDatasetPhone: TStringField;
    MainDatasetJobTitle: TStringField;
    MainDatasetStatus: TStringField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    ActiveUsersOnly: TCheckBox;
    MainDatasetOrganisationId: TIntegerField;
    MainDatasetUserScope: TStringField;
    procedure ActiveUsersOnlyClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FBuildingQuery: Boolean;
  protected
    procedure LoadData; override;
    function RecordViewOption: TViewOption; override;
    function GetEditFormClass: TFormClass; override;
    procedure SearchData(const Value: string); override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

uses
  MainDataModule,
  UserForm,
  Auth.Service,
  Grid.Settings;

{$R *.dfm}

procedure TUserListForm.ActiveUsersOnlyClick(Sender: TObject);
begin
//  if ActiveUsersOnly.Checked then
//     FGridPlugin.SetPreparedFilter('(Status eq ''Active'')', True)
//  else
//    FGridPlugin.SetPreparedFilter('', True);
end;

procedure TUserListForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  FBuildingQuery := False;
end;

{ TForm3 }

function TUserListForm.GetEditFormClass: TFormClass;
begin
  Result := TUserEditForm;
end;

procedure TUserListForm.LoadData;
begin
  FGridPlugin.Load;
end;

function TUserListForm.RecordViewOption: TViewOption;
begin
  if AuthService.IsAdministrator then
    Result := TViewOption.voCreateAndEdit
  else
    Result := TViewOption.voReadOnly;
end;

procedure TUserListForm.SearchData(const Value: string);
begin
  if FBuildingQuery then
    Exit;
  FBuildingQuery := True;

  if TUtils.IsInteger(Value) then
    FGridPlugin.SetDefinedSearch(Value, [TSearchField.Create('Id', soEQ, 0, False, True)])
  else if Pos('@', Value) > 0 then
    FGridPlugin.SetDefinedSearch(Value, [TSearchField.Create('Email', soStarts, 0, True)])
  else
    FGridPlugin.SetDefinedSearch(Value, [TSearchField.Create('FirstName', soStarts, 0, True),
      TSearchField.Create('LastName', soStarts, 0, True)]);

  FBuildingQuery := False;

end;

procedure TUserListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ActiveUsersOnly := TCheckBox.Create('ActiveUsersOnly');
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetTitle := TStringField.Create(Self);
  MainDatasetFirstName := TStringField.Create(Self);
  MainDatasetLastName := TStringField.Create(Self);
  MainDatasetUserName := TStringField.Create(Self);
  MainDatasetEmail := TStringField.Create(Self);
  MainDatasetMobile := TStringField.Create(Self);
  MainDatasetPhone := TStringField.Create(Self);
  MainDatasetJobTitle := TStringField.Create(Self);
  MainDatasetStatus := TStringField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetOrganisationId := TIntegerField.Create(Self);
  MainDatasetUserScope := TStringField.Create(Self);

  DataTable.BeforeLoadDFMValues;
  ActiveUsersOnly.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetTitle.BeforeLoadDFMValues;
  MainDatasetFirstName.BeforeLoadDFMValues;
  MainDatasetLastName.BeforeLoadDFMValues;
  MainDatasetUserName.BeforeLoadDFMValues;
  MainDatasetEmail.BeforeLoadDFMValues;
  MainDatasetMobile.BeforeLoadDFMValues;
  MainDatasetPhone.BeforeLoadDFMValues;
  MainDatasetJobTitle.BeforeLoadDFMValues;
  MainDatasetStatus.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetOrganisationId.BeforeLoadDFMValues;
  MainDatasetUserScope.BeforeLoadDFMValues;
  MainDataSource.BeforeLoadDFMValues;
  WebElementActionList1.BeforeLoadDFMValues;
  try
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'FirstName';
      Title := 'First Name';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'LastName';
      Title := 'Last Name';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Status';
      Title := 'Status';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'UserScope';
      Title := 'Scope';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    ActiveUsersOnly.SetParentComponent(Self);
    ActiveUsersOnly.Name := 'ActiveUsersOnly';
    ActiveUsersOnly.Left := 120;
    ActiveUsersOnly.Top := 67;
    ActiveUsersOnly.Width := 113;
    ActiveUsersOnly.Height := 22;
    ActiveUsersOnly.Caption := 'Only Active Users';
    ActiveUsersOnly.Checked := True;
    ActiveUsersOnly.ChildOrder := 10;
    ActiveUsersOnly.Color := clNone;
    ActiveUsersOnly.ElementClassName := 'form-control custom-checkbox';
    ActiveUsersOnly.ElementButtonClassName := 'form-control-input';
    ActiveUsersOnly.ElementLabelClassName := 'custom-control-label';
    ActiveUsersOnly.ElementFont := efCSS;
    ActiveUsersOnly.ElementPosition := epIgnore;
    ActiveUsersOnly.HeightStyle := ssAuto;
    ActiveUsersOnly.HeightPercent := 100.000000000000000000;
    ActiveUsersOnly.State := cbChecked;
    ActiveUsersOnly.Visible := False;
    ActiveUsersOnly.WidthStyle := ssAuto;
    ActiveUsersOnly.WidthPercent := 100.000000000000000000;
    SetEvent(ActiveUsersOnly, Self, 'OnClick', 'ActiveUsersOnlyClick');
    MainDataset.EntitySetName := 'smxUser';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetTitle.SetParentComponent(MainDataset);
    MainDatasetTitle.Name := 'MainDatasetTitle';
    MainDatasetTitle.FieldName := 'Title';
    MainDatasetTitle.Size := 25;
    MainDatasetFirstName.SetParentComponent(MainDataset);
    MainDatasetFirstName.Name := 'MainDatasetFirstName';
    MainDatasetFirstName.FieldName := 'FirstName';
    MainDatasetFirstName.Size := 50;
    MainDatasetLastName.SetParentComponent(MainDataset);
    MainDatasetLastName.Name := 'MainDatasetLastName';
    MainDatasetLastName.FieldName := 'LastName';
    MainDatasetLastName.Size := 50;
    MainDatasetUserName.SetParentComponent(MainDataset);
    MainDatasetUserName.Name := 'MainDatasetUserName';
    MainDatasetUserName.FieldName := 'UserName';
    MainDatasetUserName.Size := 25;
    MainDatasetEmail.SetParentComponent(MainDataset);
    MainDatasetEmail.Name := 'MainDatasetEmail';
    MainDatasetEmail.FieldName := 'Email';
    MainDatasetEmail.Size := 100;
    MainDatasetMobile.SetParentComponent(MainDataset);
    MainDatasetMobile.Name := 'MainDatasetMobile';
    MainDatasetMobile.FieldName := 'Mobile';
    MainDatasetMobile.Size := 25;
    MainDatasetPhone.SetParentComponent(MainDataset);
    MainDatasetPhone.Name := 'MainDatasetPhone';
    MainDatasetPhone.FieldName := 'Phone';
    MainDatasetPhone.Size := 25;
    MainDatasetJobTitle.SetParentComponent(MainDataset);
    MainDatasetJobTitle.Name := 'MainDatasetJobTitle';
    MainDatasetJobTitle.FieldName := 'JobTitle';
    MainDatasetJobTitle.Size := 100;
    MainDatasetStatus.SetParentComponent(MainDataset);
    MainDatasetStatus.Name := 'MainDatasetStatus';
    MainDatasetStatus.FieldName := 'Status';
    MainDatasetStatus.Size := 9;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetOrganisationId.SetParentComponent(MainDataset);
    MainDatasetOrganisationId.Name := 'MainDatasetOrganisationId';
    MainDatasetOrganisationId.FieldName := 'OrganisationId';
    MainDatasetUserScope.SetParentComponent(MainDataset);
    MainDatasetUserScope.Name := 'MainDatasetUserScope';
    MainDatasetUserScope.FieldName := 'UserScope';
    MainDatasetUserScope.Required := True;
    MainDatasetUserScope.Size := 9;
    MainDataSource.Left := 136;
    WebElementActionList1.Left := 608;
    WebElementActionList1.Top := 32;
  finally
    DataTable.AfterLoadDFMValues;
    ActiveUsersOnly.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetTitle.AfterLoadDFMValues;
    MainDatasetFirstName.AfterLoadDFMValues;
    MainDatasetLastName.AfterLoadDFMValues;
    MainDatasetUserName.AfterLoadDFMValues;
    MainDatasetEmail.AfterLoadDFMValues;
    MainDatasetMobile.AfterLoadDFMValues;
    MainDatasetPhone.AfterLoadDFMValues;
    MainDatasetJobTitle.AfterLoadDFMValues;
    MainDatasetStatus.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetOrganisationId.AfterLoadDFMValues;
    MainDatasetUserScope.AfterLoadDFMValues;
    MainDataSource.AfterLoadDFMValues;
    WebElementActionList1.AfterLoadDFMValues;
  end;
end;

end.
