unit SMX.Auth.Shared;

interface

{$IFNDEF PAS2JS}
uses Aurelius.Mapping.Attributes;
{$ENDIF}

{$SCOPEDENUMS ON}

Type
{$IFNDEF PAS2JS}
[Enumeration(TEnumMappingType.emString, 'None,ReadOnly,User,AdminUser,SuperUser,SysUser')]
{$ENDIF}
TUserScope = (None, ReadOnly, User, AdminUser, SuperUser, SysUser);

const
  CLAIM_FIRSTNAME = 'firstname';
  CLAIM_FULLNAME = 'fullname';
  CLAIM_USERID = 'userid';
  CLAIM_LOCATIONTYPE = 'locationtype';
  CLAIM_SCOPE = 'scope';
  CLAIM_ROLE = 'role';

  SCOPE_NONE = 'None';
  SCOPE_READONLY = 'ReadOnly';
  SCOPE_USER = 'User';
  SCOPE_ADMIN = 'AdminUser';
  SCOPE_SUPERUSER = 'SuperUser';
  SCOPE_SYSUSER = 'SysUser';

  SCOPE_ANY_ADMIN = 'AdminUser,SuperUser,SysUser';
  SCOPE_ALL_USERS = 'User,AdminUser,SuperUser,SysUser';

  ID_FOR_SCOPE: array[TUserScope] of Integer = (10, 11, 12, 13, 14, 15);
  User_Scopes: array[TUserScope] of string = ('None','ReadOnly','User','AdminUser','SuperUser','SysUser');

implementation

{$SCOPEDENUMS OFF}
end.
