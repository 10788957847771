unit SitesList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Lists,
  Vcl.StdCtrls,
  WEBLib.StdCtrls;

type
  TSitesListForm = class(TBaseListForm)
    MainDatasetId: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetRef: TStringField;
    MainDatasetName: TStringField;
    MainDatasetServerId: TIntegerField;
    MainDatasetClientStatus: TStringField;
    MainDatasetCSSVersion: TStringField;
    MainDatasetSelectorVersion: TStringField;
    ServerData: TXDataWebDataSet;
    ServerDataId: TIntegerField;
    ServerDataDateAdded: TDateTimeField;
    ServerDataAddedBy: TIntegerField;
    ServerDataLastUpdatedBy: TIntegerField;
    ServerDataLastUpdated: TDateTimeField;
    ServerDataRef: TStringField;
    ServerDataName: TStringField;
    ServerDataConnectionURL: TStringField;
    ServerDataFirebirdVersion: TStringField;
    ServerDataManagerVersion: TStringField;
    ServerDataSecret: TStringField;
    WebDBLabel1: TDBLabel;
    ServerSource: TDataSource;
  private
    FServerId: Integer;
    procedure SetServerId(const Value: Integer);
    [async]
    procedure ToggleSiteLock(const SiteId: Integer); async;
    { Private declarations }
  protected
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement); override;
    procedure LoadData; override;
    function GetEditFormClass: TFormClass; override;

  public
    { Public declarations }
    property ServerId: Integer read FServerId write SetServerId;
  protected procedure LoadDFMValues; override; end;

var
  SitesListForm: TSitesListForm;

implementation

{$R *.dfm}

uses
  WEBLib.WebCtrls,
  XData.Web.Client,
  SMX.Web.Layout.Utils,
  SiteForm,
  MainDataModule;

{ TSitesListForm }

procedure TSitesListForm.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);

  procedure ToggleLock(Sender: TObject);
  begin
    ToggleSiteLock(ARecordId.ToInteger);
  end;

var
  Span: THTMLSpan;
  lIcon: string;
begin
  inherited;

  if MainDatasetClientStatus.Value = 'Locked' then
    lIcon := 'fa-duotone fa-lock-open'
  else if MainDatasetClientStatus.Value = 'Active' then
    lIcon := 'fa-duotone fa-lock'
  else
    lIcon := '';

  if lIcon <> '' then
  begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, lIcon, 'show client sites');
    Span.OnClick := @ToggleLock;
  end

end;

function TSitesListForm.GetEditFormClass: TFormClass;
begin
  Result := TSiteEditForm;
end;

procedure TSitesListForm.LoadData;
begin
  // Don't do anything here, wait until the ServerId is set
end;

procedure TSitesListForm.SetServerId(const Value: Integer);
begin
  FServerId := Value;
  ServerData.QueryString := '$filter=id eq ' + Value.ToString;
  ServerData.Load;
  MainDataset.QueryString := '$filter= ServerId eq ' + Value.ToString;
  MainDataset.Load;
end;

procedure TSitesListForm.ToggleSiteLock(const SiteId: Integer);
var
  lRetval: TXDataClientResponse;
  lStatus: String;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('ICallThroughService.ToggleSiteLock', [SiteId]));
//  lStatus := lRetval.ResultAsObject.
//  Update Dataset and merge changes - somehow, if not we need to refresh the table
//  MainDataset.Close;
//  MainDataset.Load;
end;

procedure TSitesListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebDBLabel1 := TDBLabel.Create(Self);
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetRef := TStringField.Create(Self);
  MainDatasetName := TStringField.Create(Self);
  MainDatasetServerId := TIntegerField.Create(Self);
  MainDatasetClientStatus := TStringField.Create(Self);
  MainDatasetCSSVersion := TStringField.Create(Self);
  MainDatasetSelectorVersion := TStringField.Create(Self);
  ServerData := TXDataWebDataSet.Create(Self);
  ServerDataId := TIntegerField.Create(Self);
  ServerDataDateAdded := TDateTimeField.Create(Self);
  ServerDataAddedBy := TIntegerField.Create(Self);
  ServerDataLastUpdatedBy := TIntegerField.Create(Self);
  ServerDataLastUpdated := TDateTimeField.Create(Self);
  ServerDataRef := TStringField.Create(Self);
  ServerDataName := TStringField.Create(Self);
  ServerDataConnectionURL := TStringField.Create(Self);
  ServerDataFirebirdVersion := TStringField.Create(Self);
  ServerDataManagerVersion := TStringField.Create(Self);
  ServerDataSecret := TStringField.Create(Self);
  ServerSource := TDataSource.Create(Self);

  WebDBLabel1.BeforeLoadDFMValues;
  DataTable.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetRef.BeforeLoadDFMValues;
  MainDatasetName.BeforeLoadDFMValues;
  MainDatasetServerId.BeforeLoadDFMValues;
  MainDatasetClientStatus.BeforeLoadDFMValues;
  MainDatasetCSSVersion.BeforeLoadDFMValues;
  MainDatasetSelectorVersion.BeforeLoadDFMValues;
  ServerData.BeforeLoadDFMValues;
  ServerDataId.BeforeLoadDFMValues;
  ServerDataDateAdded.BeforeLoadDFMValues;
  ServerDataAddedBy.BeforeLoadDFMValues;
  ServerDataLastUpdatedBy.BeforeLoadDFMValues;
  ServerDataLastUpdated.BeforeLoadDFMValues;
  ServerDataRef.BeforeLoadDFMValues;
  ServerDataName.BeforeLoadDFMValues;
  ServerDataConnectionURL.BeforeLoadDFMValues;
  ServerDataFirebirdVersion.BeforeLoadDFMValues;
  ServerDataManagerVersion.BeforeLoadDFMValues;
  ServerDataSecret.BeforeLoadDFMValues;
  ServerSource.BeforeLoadDFMValues;
  try
    WebDBLabel1.SetParentComponent(Self);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 656;
    WebDBLabel1.Top := 88;
    WebDBLabel1.Width := 73;
    WebDBLabel1.Height := 15;
    WebDBLabel1.Caption := 'WebDBLabel1';
    WebDBLabel1.ElementFont := efCSS;
    WebDBLabel1.HeightStyle := ssAuto;
    WebDBLabel1.HeightPercent := 100.000000000000000000;
    WebDBLabel1.HTMLType := tSPAN;
    WebDBLabel1.WidthStyle := ssAuto;
    WebDBLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel1.DataSource := ServerSource;
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Ref';
      Title := 'Ref';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Name';
      Title := 'Ref';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ClientStatus';
      Title := 'Status';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    MainDataset.EntitySetName := 'MCPClientSite';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.Required := True;
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetRef.SetParentComponent(MainDataset);
    MainDatasetRef.Name := 'MainDatasetRef';
    MainDatasetRef.FieldName := 'Ref';
    MainDatasetRef.Size := 25;
    MainDatasetName.SetParentComponent(MainDataset);
    MainDatasetName.Name := 'MainDatasetName';
    MainDatasetName.FieldName := 'Name';
    MainDatasetName.Size := 250;
    MainDatasetServerId.SetParentComponent(MainDataset);
    MainDatasetServerId.Name := 'MainDatasetServerId';
    MainDatasetServerId.FieldName := 'ServerId';
    MainDatasetServerId.Required := True;
    MainDatasetClientStatus.SetParentComponent(MainDataset);
    MainDatasetClientStatus.Name := 'MainDatasetClientStatus';
    MainDatasetClientStatus.FieldName := 'ClientStatus';
    MainDatasetClientStatus.Required := True;
    MainDatasetClientStatus.Size := 9;
    MainDatasetCSSVersion.SetParentComponent(MainDataset);
    MainDatasetCSSVersion.Name := 'MainDatasetCSSVersion';
    MainDatasetCSSVersion.FieldName := 'CSSVersion';
    MainDatasetCSSVersion.Size := 15;
    MainDatasetSelectorVersion.SetParentComponent(MainDataset);
    MainDatasetSelectorVersion.Name := 'MainDatasetSelectorVersion';
    MainDatasetSelectorVersion.FieldName := 'SelectorVersion';
    MainDatasetSelectorVersion.Size := 15;
    ServerData.SetParentComponent(Self);
    ServerData.Name := 'ServerData';
    ServerData.EntitySetName := 'MCPServer';
    ServerData.Connection := MainData.DataConnection;
    ServerData.Left := 584;
    ServerData.Top := 112;
    ServerDataId.SetParentComponent(ServerData);
    ServerDataId.Name := 'ServerDataId';
    ServerDataId.FieldName := 'Id';
    ServerDataId.Required := True;
    ServerDataDateAdded.SetParentComponent(ServerData);
    ServerDataDateAdded.Name := 'ServerDataDateAdded';
    ServerDataDateAdded.FieldName := 'DateAdded';
    ServerDataDateAdded.Required := True;
    ServerDataAddedBy.SetParentComponent(ServerData);
    ServerDataAddedBy.Name := 'ServerDataAddedBy';
    ServerDataAddedBy.FieldName := 'AddedBy';
    ServerDataAddedBy.Required := True;
    ServerDataLastUpdatedBy.SetParentComponent(ServerData);
    ServerDataLastUpdatedBy.Name := 'ServerDataLastUpdatedBy';
    ServerDataLastUpdatedBy.FieldName := 'LastUpdatedBy';
    ServerDataLastUpdated.SetParentComponent(ServerData);
    ServerDataLastUpdated.Name := 'ServerDataLastUpdated';
    ServerDataLastUpdated.FieldName := 'LastUpdated';
    ServerDataRef.SetParentComponent(ServerData);
    ServerDataRef.Name := 'ServerDataRef';
    ServerDataRef.FieldName := 'Ref';
    ServerDataRef.Size := 25;
    ServerDataName.SetParentComponent(ServerData);
    ServerDataName.Name := 'ServerDataName';
    ServerDataName.FieldName := 'Name';
    ServerDataName.Size := 250;
    ServerDataConnectionURL.SetParentComponent(ServerData);
    ServerDataConnectionURL.Name := 'ServerDataConnectionURL';
    ServerDataConnectionURL.FieldName := 'ConnectionURL';
    ServerDataConnectionURL.Size := 250;
    ServerDataFirebirdVersion.SetParentComponent(ServerData);
    ServerDataFirebirdVersion.Name := 'ServerDataFirebirdVersion';
    ServerDataFirebirdVersion.FieldName := 'FirebirdVersion';
    ServerDataFirebirdVersion.Size := 15;
    ServerDataManagerVersion.SetParentComponent(ServerData);
    ServerDataManagerVersion.Name := 'ServerDataManagerVersion';
    ServerDataManagerVersion.FieldName := 'ManagerVersion';
    ServerDataManagerVersion.Size := 15;
    ServerDataSecret.SetParentComponent(ServerData);
    ServerDataSecret.Name := 'ServerDataSecret';
    ServerDataSecret.FieldName := 'Secret';
    ServerDataSecret.Size := 40;
    ServerSource.SetParentComponent(Self);
    ServerSource.Name := 'ServerSource';
    ServerSource.DataSet := ServerData;
    ServerSource.Left := 584;
    ServerSource.Top := 184;
  finally
    WebDBLabel1.AfterLoadDFMValues;
    DataTable.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetRef.AfterLoadDFMValues;
    MainDatasetName.AfterLoadDFMValues;
    MainDatasetServerId.AfterLoadDFMValues;
    MainDatasetClientStatus.AfterLoadDFMValues;
    MainDatasetCSSVersion.AfterLoadDFMValues;
    MainDatasetSelectorVersion.AfterLoadDFMValues;
    ServerData.AfterLoadDFMValues;
    ServerDataId.AfterLoadDFMValues;
    ServerDataDateAdded.AfterLoadDFMValues;
    ServerDataAddedBy.AfterLoadDFMValues;
    ServerDataLastUpdatedBy.AfterLoadDFMValues;
    ServerDataLastUpdated.AfterLoadDFMValues;
    ServerDataRef.AfterLoadDFMValues;
    ServerDataName.AfterLoadDFMValues;
    ServerDataConnectionURL.AfterLoadDFMValues;
    ServerDataFirebirdVersion.AfterLoadDFMValues;
    ServerDataManagerVersion.AfterLoadDFMValues;
    ServerDataSecret.AfterLoadDFMValues;
    ServerSource.AfterLoadDFMValues;
  end;
end;

end.
