unit ServerList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Lists,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  App.Types;

type
  TOnServerShowSites = procedure(const ServerId: Integer) of object;

  TServerListForm = class(TBaseListForm)
    MainDatasetId: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetRef: TStringField;
    MainDatasetName: TStringField;
    MainDatasetConnectionURL: TStringField;
    MainDatasetStatus: TStringField;
    MainDatasetServerType: TStringField;
    MainDatasetFirebirdVersion: TStringField;
    MainDatasetManagerVersion: TStringField;
    MainDatasetSecret: TStringField;
    procedure WebFormCreate(Sender: TObject);
  private
    FOnShowSites: TOnServerShowSites;
    { Private declarations }
    procedure ShowSites(const ServerId: Integer);
  protected
    function RecordViewOption: TViewOption; override;
    procedure LoadData; override;
    function GetEditFormClass: TFormClass; override;
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement); override;
  public
    { Public declarations }
    property OnShowSites: TOnServerShowSites write FOnShowSites;
  protected procedure LoadDFMValues; override; end;

var
  ServerListForm: TServerListForm;

implementation

{$R *.dfm}

uses
  ServerEdit,
  WEBLib.WebCtrls,
  SMX.Web.Layout.Utils;

procedure TServerListForm.WebFormCreate(Sender: TObject);
begin
  inherited;
end;

{ TServerListForm }

procedure TServerListForm.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);

  procedure SitesClick(Sender: TObject);
  begin
    ShowSites(ARecordId.ToInteger);
  end;

var
  Span: THTMLSpan;
begin
  inherited;

  Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-sitemap', 'show client sites');
  Span.OnClick := @SitesClick;

end;

function TServerListForm.GetEditFormClass: TFormClass;
begin
  Result := TServerEditForm;
end;

procedure TServerListForm.LoadData;
begin
  MainDataset.Load;
end;

function TServerListForm.RecordViewOption: TViewOption;
begin
  Result := TViewOption.voEdit;
end;

procedure TServerListForm.ShowSites(const ServerId: Integer);
begin
  if Assigned(FOnShowSites) then
    FOnShowSites(ServerId)
  else
    raise exception.Create('Show Sites Event not set');
end;

procedure TServerListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetRef := TStringField.Create(Self);
  MainDatasetName := TStringField.Create(Self);
  MainDatasetConnectionURL := TStringField.Create(Self);
  MainDatasetStatus := TStringField.Create(Self);
  MainDatasetServerType := TStringField.Create(Self);
  MainDatasetFirebirdVersion := TStringField.Create(Self);
  MainDatasetManagerVersion := TStringField.Create(Self);
  MainDatasetSecret := TStringField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);

  DataTable.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetRef.BeforeLoadDFMValues;
  MainDatasetName.BeforeLoadDFMValues;
  MainDatasetConnectionURL.BeforeLoadDFMValues;
  MainDatasetStatus.BeforeLoadDFMValues;
  MainDatasetServerType.BeforeLoadDFMValues;
  MainDatasetFirebirdVersion.BeforeLoadDFMValues;
  MainDatasetManagerVersion.BeforeLoadDFMValues;
  MainDatasetSecret.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  try
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Ref';
      Title := 'Ref';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ServerType';
      Title := 'Type';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Name';
      Title := 'Name';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    MainDataset.EntitySetName := 'MCPServer';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.Required := True;
    MainDatasetRef.SetParentComponent(MainDataset);
    MainDatasetRef.Name := 'MainDatasetRef';
    MainDatasetRef.FieldName := 'Ref';
    MainDatasetRef.Size := 25;
    MainDatasetName.SetParentComponent(MainDataset);
    MainDatasetName.Name := 'MainDatasetName';
    MainDatasetName.FieldName := 'Name';
    MainDatasetName.Size := 250;
    MainDatasetConnectionURL.SetParentComponent(MainDataset);
    MainDatasetConnectionURL.Name := 'MainDatasetConnectionURL';
    MainDatasetConnectionURL.FieldName := 'ConnectionURL';
    MainDatasetConnectionURL.Size := 250;
    MainDatasetStatus.SetParentComponent(MainDataset);
    MainDatasetStatus.Name := 'MainDatasetStatus';
    MainDatasetStatus.FieldName := 'Status';
    MainDatasetStatus.Required := True;
    MainDatasetStatus.Size := 9;
    MainDatasetServerType.SetParentComponent(MainDataset);
    MainDatasetServerType.Name := 'MainDatasetServerType';
    MainDatasetServerType.FieldName := 'ServerType';
    MainDatasetServerType.Required := True;
    MainDatasetServerType.Size := 8;
    MainDatasetFirebirdVersion.SetParentComponent(MainDataset);
    MainDatasetFirebirdVersion.Name := 'MainDatasetFirebirdVersion';
    MainDatasetFirebirdVersion.FieldName := 'FirebirdVersion';
    MainDatasetFirebirdVersion.Size := 15;
    MainDatasetManagerVersion.SetParentComponent(MainDataset);
    MainDatasetManagerVersion.Name := 'MainDatasetManagerVersion';
    MainDatasetManagerVersion.FieldName := 'ManagerVersion';
    MainDatasetManagerVersion.Size := 15;
    MainDatasetSecret.SetParentComponent(MainDataset);
    MainDatasetSecret.Name := 'MainDatasetSecret';
    MainDatasetSecret.FieldName := 'Secret';
    MainDatasetSecret.Size := 40;
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
  finally
    DataTable.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetRef.AfterLoadDFMValues;
    MainDatasetName.AfterLoadDFMValues;
    MainDatasetConnectionURL.AfterLoadDFMValues;
    MainDatasetStatus.AfterLoadDFMValues;
    MainDatasetServerType.AfterLoadDFMValues;
    MainDatasetFirebirdVersion.AfterLoadDFMValues;
    MainDatasetManagerVersion.AfterLoadDFMValues;
    MainDatasetSecret.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
  end;
end;

end.
