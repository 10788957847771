unit UserForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseForm,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  WEBLib.WebCtrls,
  SMX.Web.Utils,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.Toast,
  Vcl.Imaging.GIFImg,
  App.Types;

type
  TUserEditForm = class(TBaseEditForm)
    UserIdLabel: TDBLabel;
    Title: TDBEdit;
    FirstName: TDBEdit;
    LastName: TDBEdit;
    Email: TDBEdit;
    Mobile: TDBEdit;
    Phone: TDBEdit;
    JobTitle: TDBEdit;
    UserScope: TDBLookupComboBox;
    UserStatus: TDBComboBox;
    UserName: TDBEdit;
    UserNameWarning: THTMLSpan;
    EmailMessage: THTMLSpan;
    NewPasswordButton: TButton;
    procedure DataSourceDataChange(Sender: TObject; Field: TField);
    procedure DataSourceStateChange(Sender: TObject);
    procedure EmailExit(Sender: TObject);
    procedure NewPasswordButtonClick(Sender: TObject);
    procedure UserNameChange(Sender: TObject);
    procedure UserNameExit(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FUserNameStatus: TRefStatus;
    FValidatedUserName: string;
    FEmailValid: Boolean;

    [async]
    procedure CheckUserName; async;
    function DisplayName: string;
    procedure SetFormCaption;

    [async]
    procedure SendNewPassword; async;
  protected
    procedure AfterSetDataset; override;
    function CanSaveThis: Boolean; override;
    function GetRequiredEditLevel: string; override;
    function Validate(const AControlName: string): Boolean; override;
    procedure NewPasswordAllowed;
    procedure AfterApplyUpdates(Sender: TDataSet; Info: TResolveResults); override;
    function SaveTitle(const AStatus: TUpdateStatus): string; override;
    function SaveDescription(const AStatus: TUpdateStatus): string; override;
  public
    { Public declarations }
    procedure SetLookUpValues(const AName: string; Value: TLookupValues); override;
  protected procedure LoadDFMValues; override; end;

implementation

uses
  System.Rtti,
  Auth.Service,
  XData.Web.Client,
  MainDataModule,
  SMX.CRM.Utils,
  SMX.Auth.Shared,
  SMX.Web.Document.Utils;

{$R *.dfm}

procedure TUserEditForm.WebFormCreate(Sender: TObject);
var lScope: TUserScope;
    lStrScope: string;
begin
  inherited;
  //ReadOnly, User, AdminUser
  for lScope := Low(TUserScope) to TUserScope.AdminUser do
  begin
      lStrScope := TRttiEnumerationType.GetName<TUserScope>(lScope);
      UserScope.LookupValues.AddPair(lStrScope, TUtils.SplitOnCaps(lStrScope));
  end;

  if AuthService.UserScope > TUserScope.AdminUser then
  begin
     UserScope.LookupValues.AddPair('SuperUser', 'Super User');
  end;

  UserNameWarning.HTML.Text := '';

end;

{ TForm1 }

procedure TUserEditForm.AfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  inherited;
  ShowAnAwesomeToast(TCRMUtils.FullName(DataSource.Dataset), 'Your changes have been saved', 'far fa-address-card');
end;

procedure TUserEditForm.AfterSetDataset;
begin
  inherited;
end;

function TUserEditForm.CanSaveThis: Boolean;
begin
  result := inherited;

  case FUserNameStatus of
    RefEmpty:
      ErrorList.Add('you must set a username');
    RefNotChecked:
      ;
    RefChecking:
      ErrorList.Add('The system is currently checking the username. Please save again');
    RefUnique:
      ;
    RefInUse:
      ErrorList.Add('This username is already being used, please select another one.');
  end;

  result := result and ((not IsErrorList) or (ErrorList.Count = 0));

  NewPasswordAllowed;
end;

procedure TUserEditForm.CheckUserName;
var
  lRetval: TXDataClientResponse;
  lOutcome: Boolean;
  lUserName: string;
begin
  lUserName := UserName.Text;
  if lUserName = FValidatedUserName then
    Exit;
  FValidatedUserName := lUserName;
  lRetval := await(TXDataClientResponse, WebClient.RawInvokeAsync(ICHECK_USERNAME, [lUserName]));
  lOutcome := Boolean(TJSObject(lRetval.result)['value']);
  if lOutcome then
  begin
    FUserNameStatus := RefUnique;
    UserNameWarning.HTML.Text := '<i class="fad fa-check"></i> OK';
    UserNameWarning.ElementClassName := 'text-success';
    UserNameWarning.Hint := '';
  end
  else
  begin
    FUserNameStatus := RefInUse;
    UserNameWarning.HTML.Text := '<i class="fad fa-exclamation-triangle"></i> In Use';
    UserNameWarning.ElementClassName := 'text-warning';
    UserNameWarning.Hint := 'This username is already in use';
  end;

end;

procedure TUserEditForm.DataSourceDataChange(Sender: TObject; Field: TField);
begin
  inherited;
  if Field = nil then
  begin
    SetFormCaption;

    UserName.Enabled := DataSource.State = TDatasetState.dsInsert;

    if DataSource.State <> TDatasetState.dsInsert then
    begin
      FUserNameStatus := RefUnique;
      UserNameWarning.HTML.Text := ''; // '<i class="fad fa-check"></i> OK';
      UserNameWarning.ElementClassName := 'text-success';
      UserNameWarning.Hint := '';
      if FLoading then
        UserStatus.SetFocus;
    end
    else
    begin
      if DataSource.Dataset.FieldByName('Id').AsString = '' then
      begin
        FUserNameStatus := RefEmpty;
        UserNameWarning.HTML.Text := '<i class="fad fa-user-tag"></i> Username Needed';
        UserNameWarning.ElementClassName := 'text-info';
      end
      else
        FUserNameStatus := RefNotChecked;
      if FLoading then
        UserName.SetFocus;
    end;

  end;
end;

procedure TUserEditForm.DataSourceStateChange(Sender: TObject);
begin
  inherited;
  NewPasswordAllowed;
end;

function TUserEditForm.DisplayName: string;
begin
  result := TCRMUtils.FullName(DataSource.Dataset);
  if result = '' then
    result := DataSource.Dataset.FieldByName('Username').AsString
end;

procedure TUserEditForm.EmailExit(Sender: TObject);
begin
  inherited;
  FEmailValid := TCRMUtils.IsEmailValid(Email.Text);
  if not FEmailValid then
  begin
    TDocUtils.addClass('Email', 'is-invalid');
    if DataSource.State = dsInsert then
      EmailMessage.HTML.Text := 'Without a valid email this user can''t be sent a password';
  end
  else
  begin
    TDocUtils.removeClass('Email', 'is-invalid');
    EmailMessage.HTML.Text := '';
  end;
end;

function TUserEditForm.GetRequiredEditLevel: string;
begin
  result := SCOPE_ADMIN;
end;

procedure TUserEditForm.NewPasswordAllowed;
begin
  NewPasswordButton.Enabled := (not(DataSource.State in dsEditModes)) and
    (DataSource.Dataset.FieldByName('Email').AsString <> '');
end;

procedure TUserEditForm.NewPasswordButtonClick(Sender: TObject);
begin
  SendNewPassword;
end;

function TUserEditForm.SaveDescription(const AStatus: TUpdateStatus): string;
begin
  case AStatus of
    TUpdateStatus.usModified:
      result := 'Changes for ' + DisplayName + ' have been saved';
    TUpdateStatus.usInserted:
      result := DisplayName + ' has been added. If they have an email and username a password will be emailed to them.';
  else
    result := inherited;
  end;
end;

function TUserEditForm.SaveTitle(const AStatus: TUpdateStatus): string;
begin
  case AStatus of
    TUpdateStatus.usModified:
      result := 'Changes for ' + DisplayName;
    TUpdateStatus.usInserted:
      result := DisplayName + ' added.';
  else
    result := inherited;
  end;
end;

procedure TUserEditForm.SendNewPassword;
var
  lUserName: string;
begin
  lUserName := UserName.Text;
  await(TXDataClientResponse, MainData.AuthClient.RawInvokeAsync(IFORGOT_PASSWORD, [lUserName]));
  ShowAnAwesomeToast('Password', 'A new password has been sent to ' + DataSource.Dataset.FieldByName('FirstName')
    .AsString, 'fa fa-key');
end;

procedure TUserEditForm.SetFormCaption;
var
  lName: string;
begin
  lName := DisplayName;
  if lName = '' then
    FormCaption.Caption := ActionText + 'a new user'
  else
    FormCaption.Caption := ActionText + lName;
end;

procedure TUserEditForm.SetLookUpValues(const AName: string; Value: TLookupValues);
begin

end;

procedure TUserEditForm.UserNameChange(Sender: TObject);
begin
  UserName.Text := Uppercase(UserName.Text);
end;

procedure TUserEditForm.UserNameExit(Sender: TObject);
begin
  if (DataSource.State = dsInsert) and (UserName.Text <> FValidatedUserName) then
  begin
    CheckUserName;
  end;
end;

function TUserEditForm.Validate(const AControlName: string): Boolean;
begin
  result := inherited;
end;

procedure TUserEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  UserIdLabel := TDBLabel.Create('UserIdLabel');
  FirstName := TDBEdit.Create('FirstName');
  LastName := TDBEdit.Create('LastName');
  Email := TDBEdit.Create('Email');
  Mobile := TDBEdit.Create('Mobile');
  Phone := TDBEdit.Create('Phone');
  JobTitle := TDBEdit.Create('JobTitle');
  UserScope := TDBLookupComboBox.Create('UserScope');
  UserStatus := TDBComboBox.Create('UserStatus');
  UserName := TDBEdit.Create('UserName');
  Title := TDBEdit.Create('Title');
  UserNameWarning := THTMLSpan.Create('UserNameWarning');
  EmailMessage := THTMLSpan.Create('EmailMessage');
  NewPasswordButton := TButton.Create('NewPasswordButton');

  UserIdLabel.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  FirstName.BeforeLoadDFMValues;
  LastName.BeforeLoadDFMValues;
  Email.BeforeLoadDFMValues;
  Mobile.BeforeLoadDFMValues;
  Phone.BeforeLoadDFMValues;
  JobTitle.BeforeLoadDFMValues;
  UserScope.BeforeLoadDFMValues;
  UserStatus.BeforeLoadDFMValues;
  UserName.BeforeLoadDFMValues;
  Title.BeforeLoadDFMValues;
  UserNameWarning.BeforeLoadDFMValues;
  EmailMessage.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  DataState.BeforeLoadDFMValues;
  NewPasswordButton.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  EditElements.BeforeLoadDFMValues;
  CloseTimer.BeforeLoadDFMValues;
  try
    UserIdLabel.SetParentComponent(Self);
    UserIdLabel.Name := 'UserIdLabel';
    UserIdLabel.Left := 32;
    UserIdLabel.Top := 400;
    UserIdLabel.Width := 61;
    UserIdLabel.Height := 15;
    UserIdLabel.Caption := 'UserIdLabel';
    UserIdLabel.HeightPercent := 100.000000000000000000;
    UserIdLabel.WidthPercent := 100.000000000000000000;
    UserIdLabel.DataField := 'Id';
    UserIdLabel.DataSource := DataSource;
    CancelButton.TabOrder := 12;
    FirstName.SetParentComponent(Self);
    FirstName.Name := 'FirstName';
    FirstName.Left := 112;
    FirstName.Top := 134;
    FirstName.Width := 200;
    FirstName.Height := 21;
    FirstName.ChildOrder := 5;
    FirstName.ElementClassName := 'form-control';
    FirstName.HeightPercent := 100.000000000000000000;
    FirstName.TabOrder := 6;
    FirstName.Text := 'FirstName';
    FirstName.WidthPercent := 100.000000000000000000;
    FirstName.DataField := 'FirstName';
    FirstName.DataSource := DataSource;
    LastName.SetParentComponent(Self);
    LastName.Name := 'LastName';
    LastName.Left := 109;
    LastName.Top := 161;
    LastName.Width := 200;
    LastName.Height := 21;
    LastName.ChildOrder := 6;
    LastName.ElementClassName := 'form-control';
    LastName.HeightPercent := 100.000000000000000000;
    LastName.TabOrder := 7;
    LastName.Text := 'LastName';
    LastName.WidthPercent := 100.000000000000000000;
    LastName.DataField := 'LastName';
    LastName.DataSource := DataSource;
    Email.SetParentComponent(Self);
    Email.Name := 'Email';
    Email.Left := 109;
    Email.Top := 188;
    Email.Width := 200;
    Email.Height := 21;
    Email.AutoCompletion := acNone;
    Email.CharCase := wecLowerCase;
    Email.ChildOrder := 7;
    Email.ElementClassName := 'form-control';
    Email.HeightPercent := 100.000000000000000000;
    Email.TabOrder := 8;
    Email.Text := 'Email';
    Email.WidthPercent := 100.000000000000000000;
    SetEvent(Email, Self, 'OnExit', 'EmailExit');
    Email.DataField := 'Email';
    Email.DataSource := DataSource;
    Mobile.SetParentComponent(Self);
    Mobile.Name := 'Mobile';
    Mobile.Left := 109;
    Mobile.Top := 215;
    Mobile.Width := 200;
    Mobile.Height := 21;
    Mobile.AutoCompletion := acTelephone;
    Mobile.ChildOrder := 8;
    Mobile.ElementClassName := 'form-control';
    Mobile.HeightPercent := 100.000000000000000000;
    Mobile.TabOrder := 10;
    Mobile.Text := 'Mobile';
    Mobile.WidthPercent := 100.000000000000000000;
    Mobile.DataField := 'Mobile';
    Mobile.DataSource := DataSource;
    Phone.SetParentComponent(Self);
    Phone.Name := 'Phone';
    Phone.Left := 109;
    Phone.Top := 242;
    Phone.Width := 200;
    Phone.Height := 21;
    Phone.AutoCompletion := acTelephone;
    Phone.ChildOrder := 9;
    Phone.ElementClassName := 'form-control';
    Phone.HeightPercent := 100.000000000000000000;
    Phone.TabOrder := 9;
    Phone.Text := 'Phone';
    Phone.WidthPercent := 100.000000000000000000;
    Phone.DataField := 'Phone';
    Phone.DataSource := DataSource;
    JobTitle.SetParentComponent(Self);
    JobTitle.Name := 'JobTitle';
    JobTitle.Left := 333;
    JobTitle.Top := 80;
    JobTitle.Width := 200;
    JobTitle.Height := 21;
    JobTitle.ChildOrder := 11;
    JobTitle.ElementClassName := 'form-control';
    JobTitle.HeightPercent := 100.000000000000000000;
    JobTitle.TabOrder := 4;
    JobTitle.Text := 'JobTitle';
    JobTitle.WidthPercent := 100.000000000000000000;
    JobTitle.DataField := 'JobTitle';
    JobTitle.DataSource := DataSource;
    UserScope.SetParentComponent(Self);
    UserScope.Name := 'UserScope';
    UserScope.Left := 443;
    UserScope.Top := 52;
    UserScope.Width := 145;
    UserScope.Height := 22;
    UserScope.ElementClassName := 'form-select';
    UserScope.HeightPercent := 100.000000000000000000;
    UserScope.TabOrder := 2;
    UserScope.WidthPercent := 100.000000000000000000;
    UserScope.DataField := 'UserScope';
    UserScope.DataSource := DataSource;
    UserStatus.SetParentComponent(Self);
    UserStatus.Name := 'UserStatus';
    UserStatus.Left := 272;
    UserStatus.Top := 53;
    UserStatus.Width := 145;
    UserStatus.Height := 23;
    UserStatus.ElementClassName := 'form-select';
    UserStatus.HeightPercent := 100.000000000000000000;
    UserStatus.TabOrder := 1;
    UserStatus.Text := 'UserStatus';
    UserStatus.WidthPercent := 100.000000000000000000;
    UserStatus.ItemIndex := -1;
    UserStatus.Items.BeginUpdate;
    try
      UserStatus.Items.Clear;
      UserStatus.Items.Add('Active');
      UserStatus.Items.Add('Left');
      UserStatus.Items.Add('Retired');
      UserStatus.Items.Add('Suspended');
      UserStatus.Items.Add('Deleted');
    finally
      UserStatus.Items.EndUpdate;
    end;
    UserStatus.DataField := 'Status';
    UserStatus.DataSource := DataSource;
    UserName.SetParentComponent(Self);
    UserName.Name := 'UserName';
    UserName.Left := 48;
    UserName.Top := 52;
    UserName.Width := 197;
    UserName.Height := 22;
    UserName.AutoCompletion := acNone;
    UserName.CharCase := wecUpperCase;
    UserName.ChildOrder := 14;
    UserName.ElementClassName := 'form-control';
    UserName.HeightPercent := 100.000000000000000000;
    UserName.Required := True;
    UserName.Text := 'UserName';
    UserName.WidthPercent := 100.000000000000000000;
    SetEvent(UserName, Self, 'OnChange', 'UserNameChange');
    SetEvent(UserName, Self, 'OnExit', 'UserNameExit');
    UserName.DataField := 'UserName';
    UserName.DataSource := DataSource;
    Title.SetParentComponent(Self);
    Title.Name := 'Title';
    Title.Left := 109;
    Title.Top := 107;
    Title.Width := 200;
    Title.Height := 21;
    Title.ChildOrder := 4;
    Title.ElementClassName := 'form-control';
    Title.HeightPercent := 100.000000000000000000;
    Title.TabOrder := 5;
    Title.Text := 'Title';
    Title.WidthPercent := 100.000000000000000000;
    Title.DataField := 'Title';
    Title.DataSource := DataSource;
    UserNameWarning.SetParentComponent(Self);
    UserNameWarning.Name := 'UserNameWarning';
    UserNameWarning.Left := 333;
    UserNameWarning.Top := 114;
    UserNameWarning.Width := 100;
    UserNameWarning.Height := 41;
    UserNameWarning.HeightStyle := ssAuto;
    UserNameWarning.WidthStyle := ssAuto;
    UserNameWarning.ChildOrder := 16;
    UserNameWarning.ElementPosition := epIgnore;
    UserNameWarning.ElementFont := efCSS;
    UserNameWarning.Role := '';
    EmailMessage.SetParentComponent(Self);
    EmailMessage.Name := 'EmailMessage';
    EmailMessage.Left := 336;
    EmailMessage.Top := 168;
    EmailMessage.Width := 100;
    EmailMessage.Height := 41;
    EmailMessage.HeightStyle := ssAuto;
    EmailMessage.WidthStyle := ssAuto;
    EmailMessage.ChildOrder := 17;
    EmailMessage.ElementPosition := epIgnore;
    EmailMessage.ElementFont := efCSS;
    EmailMessage.Role := '';
    SaveButton.TabOrder := 11;
    DataState.Left := 488;
    DataState.Top := 114;
    NewPasswordButton.SetParentComponent(Self);
    NewPasswordButton.Name := 'NewPasswordButton';
    NewPasswordButton.Left := 288;
    NewPasswordButton.Top := 296;
    NewPasswordButton.Width := 96;
    NewPasswordButton.Height := 25;
    NewPasswordButton.Caption := 'New Password';
    NewPasswordButton.ChildOrder := 22;
    NewPasswordButton.ElementClassName := 'btn  btn-info';
    NewPasswordButton.ElementFont := efCSS;
    NewPasswordButton.ElementPosition := epIgnore;
    NewPasswordButton.HeightStyle := ssAuto;
    NewPasswordButton.HeightPercent := 100.000000000000000000;
    NewPasswordButton.TabOrder := 13;
    NewPasswordButton.WidthStyle := ssAuto;
    NewPasswordButton.WidthPercent := 100.000000000000000000;
    SetEvent(NewPasswordButton, Self, 'OnClick', 'NewPasswordButtonClick');
    DataSource.Left := 296;
    DataSource.Top := 8;
    EditElements.Left := 344;
    EditElements.Top := 8;
    CloseTimer.Left := 96;
    CloseTimer.Top := 312;
  finally
    UserIdLabel.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    FirstName.AfterLoadDFMValues;
    LastName.AfterLoadDFMValues;
    Email.AfterLoadDFMValues;
    Mobile.AfterLoadDFMValues;
    Phone.AfterLoadDFMValues;
    JobTitle.AfterLoadDFMValues;
    UserScope.AfterLoadDFMValues;
    UserStatus.AfterLoadDFMValues;
    UserName.AfterLoadDFMValues;
    Title.AfterLoadDFMValues;
    UserNameWarning.AfterLoadDFMValues;
    EmailMessage.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    DataState.AfterLoadDFMValues;
    NewPasswordButton.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    EditElements.AfterLoadDFMValues;
    CloseTimer.AfterLoadDFMValues;
  end;
end;

end.
