unit ServerEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseForm, WEBLib.ExtCtrls, WEBLib.Actions, Data.DB, WEBLib.DB, WEBLib.WebCtrls,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.DBCtrls;

type
  TServerEditForm = class(TBaseEditForm)
    ServerId: TDBLabel;
    ServerName: TDBEdit;
    ServerRef: TDBEdit;
    ConnectionURL: TDBEdit;
    ServerType: TDBComboBox;
    ServerStatus: TDBComboBox;
    FirebirdVersion: TDBLabel;
    ManagerVersion: TDBLabel;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  ServerEditForm: TServerEditForm;

implementation

{$R *.dfm}

procedure TServerEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ServerId := TDBLabel.Create('ServerId');
  FirebirdVersion := TDBLabel.Create('FirebirdVersion');
  ManagerVersion := TDBLabel.Create('ManagerVersion');
  ServerName := TDBEdit.Create('ServerName');
  ServerRef := TDBEdit.Create('ServerRef');
  ConnectionURL := TDBEdit.Create('ConnectionURL');
  ServerType := TDBComboBox.Create('ServerType');
  ServerStatus := TDBComboBox.Create('ServerStatus');

  ServerId.BeforeLoadDFMValues;
  FirebirdVersion.BeforeLoadDFMValues;
  ManagerVersion.BeforeLoadDFMValues;
  ServerName.BeforeLoadDFMValues;
  ServerRef.BeforeLoadDFMValues;
  ConnectionURL.BeforeLoadDFMValues;
  ServerType.BeforeLoadDFMValues;
  ServerStatus.BeforeLoadDFMValues;
  try
    ServerId.SetParentComponent(Self);
    ServerId.Name := 'ServerId';
    ServerId.Left := 72;
    ServerId.Top := 160;
    ServerId.Width := 42;
    ServerId.Height := 15;
    ServerId.Caption := 'ServerId';
    ServerId.ElementFont := efCSS;
    ServerId.ElementPosition := epIgnore;
    ServerId.HeightStyle := ssAuto;
    ServerId.HeightPercent := 100.000000000000000000;
    ServerId.WidthStyle := ssAuto;
    ServerId.WidthPercent := 100.000000000000000000;
    ServerId.DataField := 'Id';
    ServerId.DataSource := DataSource;
    FirebirdVersion.SetParentComponent(Self);
    FirebirdVersion.Name := 'FirebirdVersion';
    FirebirdVersion.Left := 248;
    FirebirdVersion.Top := 272;
    FirebirdVersion.Width := 78;
    FirebirdVersion.Height := 15;
    FirebirdVersion.Caption := 'FirebirdVersion';
    FirebirdVersion.ElementFont := efCSS;
    FirebirdVersion.ElementPosition := epIgnore;
    FirebirdVersion.HeightStyle := ssAuto;
    FirebirdVersion.HeightPercent := 100.000000000000000000;
    FirebirdVersion.WidthStyle := ssAuto;
    FirebirdVersion.WidthPercent := 100.000000000000000000;
    FirebirdVersion.DataField := 'FirebirdVersion';
    FirebirdVersion.DataSource := DataSource;
    ManagerVersion.SetParentComponent(Self);
    ManagerVersion.Name := 'ManagerVersion';
    ManagerVersion.Left := 248;
    ManagerVersion.Top := 304;
    ManagerVersion.Width := 85;
    ManagerVersion.Height := 15;
    ManagerVersion.Caption := 'ManagerVersion';
    ManagerVersion.ElementFont := efCSS;
    ManagerVersion.ElementPosition := epIgnore;
    ManagerVersion.HeightStyle := ssAuto;
    ManagerVersion.HeightPercent := 100.000000000000000000;
    ManagerVersion.WidthStyle := ssAuto;
    ManagerVersion.WidthPercent := 100.000000000000000000;
    ManagerVersion.DataField := 'ManagerVersion';
    ManagerVersion.DataSource := DataSource;
    ServerName.SetParentComponent(Self);
    ServerName.Name := 'ServerName';
    ServerName.Left := 72;
    ServerName.Top := 192;
    ServerName.Width := 121;
    ServerName.Height := 22;
    ServerName.ChildOrder := 11;
    ServerName.ElementClassName := 'form-control';
    ServerName.ElementFont := efCSS;
    ServerName.ElementPosition := epIgnore;
    ServerName.HeightStyle := ssAuto;
    ServerName.HeightPercent := 100.000000000000000000;
    ServerName.Text := 'ServerName';
    ServerName.WidthStyle := ssAuto;
    ServerName.WidthPercent := 100.000000000000000000;
    ServerName.DataField := 'Name';
    ServerName.DataSource := DataSource;
    ServerRef.SetParentComponent(Self);
    ServerRef.Name := 'ServerRef';
    ServerRef.Left := 72;
    ServerRef.Top := 232;
    ServerRef.Width := 121;
    ServerRef.Height := 22;
    ServerRef.ChildOrder := 11;
    ServerRef.ElementClassName := 'form-control';
    ServerRef.ElementFont := efCSS;
    ServerRef.ElementPosition := epIgnore;
    ServerRef.HeightStyle := ssAuto;
    ServerRef.HeightPercent := 100.000000000000000000;
    ServerRef.Text := 'ServerRef';
    ServerRef.WidthStyle := ssAuto;
    ServerRef.WidthPercent := 100.000000000000000000;
    ServerRef.DataField := 'Ref';
    ServerRef.DataSource := DataSource;
    ConnectionURL.SetParentComponent(Self);
    ConnectionURL.Name := 'ConnectionURL';
    ConnectionURL.Left := 72;
    ConnectionURL.Top := 272;
    ConnectionURL.Width := 121;
    ConnectionURL.Height := 22;
    ConnectionURL.ChildOrder := 13;
    ConnectionURL.ElementClassName := 'form-control';
    ConnectionURL.ElementFont := efCSS;
    ConnectionURL.ElementPosition := epIgnore;
    ConnectionURL.HeightStyle := ssAuto;
    ConnectionURL.HeightPercent := 100.000000000000000000;
    ConnectionURL.Text := 'ConnectionURL';
    ConnectionURL.WidthStyle := ssAuto;
    ConnectionURL.WidthPercent := 100.000000000000000000;
    ConnectionURL.DataField := 'ConnectionURL';
    ConnectionURL.DataSource := DataSource;
    ServerType.SetParentComponent(Self);
    ServerType.Name := 'ServerType';
    ServerType.Left := 251;
    ServerType.Top := 192;
    ServerType.Width := 145;
    ServerType.Height := 23;
    ServerType.ElementClassName := 'form-control';
    ServerType.ElementFont := efCSS;
    ServerType.ElementPosition := epIgnore;
    ServerType.HeightStyle := ssAuto;
    ServerType.HeightPercent := 100.000000000000000000;
    ServerType.Text := 'ServerType';
    ServerType.WidthPercent := 100.000000000000000000;
    ServerType.ItemIndex := -1;
    ServerType.Items.BeginUpdate;
    try
      ServerType.Items.Clear;
      ServerType.Items.Add('CSS');
      ServerType.Items.Add('Selector');
      ServerType.Items.Add('SelectorSecondary');
      ServerType.Items.Add('Custom');
      ServerType.Items.Add('MainData');
    finally
      ServerType.Items.EndUpdate;
    end;
    ServerType.DataField := 'ServerType';
    ServerType.DataSource := DataSource;
    ServerStatus.SetParentComponent(Self);
    ServerStatus.Name := 'ServerStatus';
    ServerStatus.Left := 248;
    ServerStatus.Top := 232;
    ServerStatus.Width := 145;
    ServerStatus.Height := 23;
    ServerStatus.ElementClassName := 'form-control';
    ServerStatus.ElementFont := efCSS;
    ServerStatus.ElementPosition := epIgnore;
    ServerStatus.HeightStyle := ssAuto;
    ServerStatus.HeightPercent := 100.000000000000000000;
    ServerStatus.Text := 'ServerStatus';
    ServerStatus.WidthPercent := 100.000000000000000000;
    ServerStatus.ItemIndex := -1;
    ServerStatus.Items.BeginUpdate;
    try
      ServerStatus.Items.Clear;
      ServerStatus.Items.Add('New');
      ServerStatus.Items.Add('Active');
      ServerStatus.Items.Add('Suspended');
      ServerStatus.Items.Add('Removed');
    finally
      ServerStatus.Items.EndUpdate;
    end;
    ServerStatus.DataField := 'Status';
    ServerStatus.DataSource := DataSource;
  finally
    ServerId.AfterLoadDFMValues;
    FirebirdVersion.AfterLoadDFMValues;
    ManagerVersion.AfterLoadDFMValues;
    ServerName.AfterLoadDFMValues;
    ServerRef.AfterLoadDFMValues;
    ConnectionURL.AfterLoadDFMValues;
    ServerType.AfterLoadDFMValues;
    ServerStatus.AfterLoadDFMValues;
  end;
end;

end.
